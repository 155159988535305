import React from "react"
import { graphql, Link } from "gatsby"
import { Box, FormControl, SimpleGrid } from "@chakra-ui/core"
import * as R from "ramda"
import { useSelector } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"

import { ConnectedTextField } from "../../components/FormFields/TextField"
import { ConnectedSelectField } from "../../components/FormFields/SelectField"
import { ConnectedDateField } from "../../components/FormFields/DateField"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import { Button } from "../../components/Buttons"
import Heading from "../../components/typography/Heading"
import Body from "../../components/typography/Body"
import Paragraph from "../../components/typography/Paragraph"

import { postProductRegistration } from "../../api/productRegistration"
import { bp } from "../../utils/MediaQueries"
import { BaseRootState } from "../../redux/store"
import { ProductRegistrationQuery } from "../../../graphql-types"
import PageContainer from "../../components/PageContainer"
import SEO from "../../components/boilerplate/seo"

export const query = graphql`
  query ProductRegistration {
    bicycles: allContentfulBicycleModel(filter: { hidden: { ne: true } }) {
      edges {
        node {
          title
          gender
          speeds {
            speed
            variants {
              title
            }
          }
        }
      }
    }
  }
`

type ProductRegistrationProps = {
  data: ProductRegistrationQuery
  location: Location
}

type FormValues = {
  productName?: string
  productSpeed?: string
  productColor?: string
  serialNumbers: string[]
  purchaseDate: string
  customerEmail: string
  customerName: string
  extraFields: {
    order_number: string
    where_did_you_purchase_your_bike: string
  }
}

export default function ProductRegistration({
  data,
  location,
}: ProductRegistrationProps) {
  const { email: defaultEmail, name: defaultName } = useSelector(
    (state: BaseRootState) => state.user
  )
  const methods = useForm<FormValues>({
    defaultValues: {
      customerName: defaultName || "",
      customerEmail: defaultEmail || "",
    },
  })
  const [isLoading, setIsLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  const productName = methods.watch("productName")
  const productSpeed = methods.watch("productSpeed")

  const onSubmit = async (data: FormValues) => {
    setError(false)
    setSuccess(false)
    setIsLoading(true)
    try {
      let name = data.productName
      if (data.productSpeed) name += `, ${data.productSpeed} speed`
      if (data.productColor) name += `, ${data.productColor}`

      const response = await postProductRegistration({
        ...data,
        productName: name,
      })
      if (response?.data?.success) {
        setSuccess(true)
      } else {
        setError(true)
      }
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const bikeToLabel = ({
    node: { title, gender },
  }: typeof data.bicycles.edges[0]) => {
    return `${
      gender === "Mens" || gender === "Womens" ? `${gender} ` : ""
    }${title}`
  }

  const bikeOptions = React.useMemo(
    () => R.uniq(data.bicycles.edges.map((bike) => bikeToLabel(bike))).sort(),
    []
  )

  const speedOptions = React.useMemo(
    () =>
      R.uniq(
        (productName &&
          data.bicycles.edges
            .find((bike) => bikeToLabel(bike) === productName)
            ?.node.speeds?.map((speed) => speed?.speed)
            .filter((x) => !!x)
            .sort((a, b) => {
              const numA = Number(a!.replace(/\D/g, ""))
              const numB = Number(b!.replace(/\D/g, ""))
              return numA - numB
            })) ||
          []
      ),
    [productName]
  )

  const colorOptions = React.useMemo(
    () =>
      R.uniq(
        (productSpeed &&
          data.bicycles.edges
            .find((bike) => bikeToLabel(bike) === productName)
            ?.node.speeds?.find((speed) => productSpeed === speed?.speed)
            ?.variants?.map((variant) => variant?.title)) ||
          []
      ),
    [productSpeed]
  )

  return (
    <PageContainer>
      <SEO title="Register Bike" location={location} />
      <Heading textAlign="center" mb="80">
        <strong>Register</strong> Bike
      </Heading>

      <FormProvider {...methods}>
        <MaxWidthGrid>
          <Box
            as="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            gridColumn={bp("1/end", "4/12")}
            mb="200"
          >
            <ConnectedSelectField
              placeholder="Bike Model"
              name="productName"
              mb="1.25rem"
              onChange={() => {
                methods.setValue("productSpeed", undefined)
                methods.setValue("productColor", undefined)
              }}
            >
              {bikeOptions.map((value) => (
                <option key={value} value={value!}>
                  {value}
                </option>
              ))}
            </ConnectedSelectField>
            <SimpleGrid columns={bp(1, 2)} spacingX="2.5rem" spacingY="1.25rem">
              <ConnectedSelectField
                placeholder="Speed"
                name="productSpeed"
                isDisabled={!productName}
                isRequired={speedOptions.length > 0}
                onChange={() => {
                  methods.setValue("productColor", undefined)
                }}
              >
                {speedOptions.map((value) => (
                  <option key={value} value={value || ""}>
                    {`${value} speed`}
                  </option>
                ))}
              </ConnectedSelectField>
              <ConnectedSelectField
                placeholder="Color"
                name="productColor"
                isDisabled={!productSpeed}
                isRequired={colorOptions.length > 0}
              >
                {colorOptions.map((value) => (
                  <option key={value} value={value || ""}>
                    {value}
                  </option>
                ))}
              </ConnectedSelectField>
              <Box>
                <ConnectedTextField
                  placeholder="Serial Number"
                  name="serialNumbers[0]"
                  isRequired
                />
              </Box>
              <Box>
                <ConnectedTextField
                  placeholder="Order Number"
                  name="extraFields.order number"
                  isRequired
                />
              </Box>
              <ConnectedSelectField
                placeholder="Where did you purchase your bike?"
                name="extraFields.where did you purchase your bike?"
                isRequired
              >
                <option value="quran411.website">sixthreezero.com</option>
                <option value="amazon.com">amazon.com</option>
                <option value="sixthreezero Website">sixthreezero.com</option>
                <option value="Amazon.com">amazon.com</option>
                <option value="Woot.com">Woot.com</option>
                <option value="Bed Bath & Beyond">{"Bed Bath & Beyond"}</option>
                <option value="WalMart">WalMart</option>
                <option value="Target">Target</option>
                <option value="Kohl's">{"Kohl's"}</option>
                <option value="Lowe's">{"Lowe's"}</option>
                <option value="Saks">Saks</option>
                <option value="Independent Bike Shop">
                  Independent Bike Shop
                </option>
                <option value="other">Other</option>
              </ConnectedSelectField>
              <ConnectedDateField
                placeholder="Purchase Date"
                name="purchaseDate"
                isRequired
              />
              <ConnectedTextField
                name="customerName"
                placeholder="Name"
                isRequired
              />
              <ConnectedTextField
                name="customerEmail"
                placeholder="Email"
                type="email"
                isRequired
              />
            </SimpleGrid>
            <Box textAlign="center">
              <Button
                type="submit"
                mt="2.75rem"
                w={bp("100%", "auto")}
                isLoading={isLoading}
              >
                Register
              </Button>
              {success && (
                <Body
                  size="md"
                  fontWeight="semibold"
                  color="primary"
                  mt="1.125rem"
                >
                  Thanks! Your bike has been registered.
                </Body>
              )}
              {error && (
                <Body
                  size="md"
                  fontWeight="semibold"
                  color="sunrise"
                  mt="1.125rem"
                >
                  Something went wrong. Please try again later
                </Body>
              )}
            </Box>
          </Box>
        </MaxWidthGrid>
      </FormProvider>
      <MaxWidthGrid mb="200">
        <Box gridColumn={bp("1/end", "4/12")}>
          <Body fontWeight="bold" mb="20">
            Warranty Information
          </Body>
          <Paragraph size="md" mb="40">
            After registering, your bike will be covered by our Standard 1-Year
            Warranty. And, in the unlikely event your bike ever gets nabbed by
            bad people who steal, we will have your serial number on file which
            will make locating it a whole lot easier. We do offer a FOREVER
            WARRANTY extension that will extend the warranty for the life of the
            bike, or as long as you (the original purchaser) own&apos;s the
            bicycle.
          </Paragraph>
          <Link to="/pages/warranty">
            <Button>View Warranty Info</Button>
          </Link>
        </Box>
      </MaxWidthGrid>
    </PageContainer>
  )
}
